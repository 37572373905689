@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

html,
body,
#__next {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    scrollbar-width: thin !important;
    scrollbar-color: #444 #666 !important;
}

.bold {
    font-weight: bold !important;
}

.boldy {
    font-weight: 500;
}

.display-block {
    display: block;
}
